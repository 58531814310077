import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Logo from '../img/logo.png';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { isAuthenticated } from "../api/AuthToken";

const SideBar = (props) => {
    const [toggled, setToggled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setToggled(false);
    }, [location]);

    useEffect(() => {
        props.setLoggedIn(isAuthenticated());
    }, []);

    return (
        <>
        <div className='app-logo row mt-4' onClick={() => setToggled(!toggled)}>
            <div className='col-3'>
                <img src={Logo} className='img-logo' style={{ height: "40px" }}/>
            </div>
            <div className='col-9 pt-2' >
                <h1 href="/" className='spartan semi-bold app-name'>Rentify</h1>
            </div>
        </div>

        <div id='side-bar-box'>
            <Sidebar backgroundColor="var(--secondary)" transitionDuration={1000} width="13rem" onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always" id='side-bar'>
                <Menu>
                    <MenuItem id='menu-item' component={<Link to="/" />} className={location.pathname === "/" ? "active" : ""}>
                        <i className="bi bi-house-fill"></i> Dashboard
                    </MenuItem>
                    <MenuItem id='menu-item' component={<Link to="/list" />} className={location.pathname === "/list" ? "active" : ""}>
                        <i className="bi bi-stack"></i> GPU List
                    </MenuItem>
                    <MenuItem id='menu-item' component={<Link to="/chat" />} className={location.pathname === "/chat" ? "active" : ""}>
                        <i className="bi bi-chat-fill"></i> Community
                    </MenuItem>
                    <MenuItem id='menu-item' component={<Link to="/order" />} className={location.pathname === "/order" ? "active" : ""}>
                        <i className="bi bi-cart-dash-fill"></i> My Order
                    </MenuItem>
                    <MenuItem id='menu-item' component={<Link to="/profile" />} className={location.pathname === "/profile" ? "active" : ""}>
                        <i className="bi bi-person-fill"></i> Profile
                    </MenuItem>
                </Menu>
            </Sidebar>
        </div>
        </>
    )
}

export default SideBar;
