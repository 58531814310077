import React from 'react';

const FooterBar = (props) => {
    return (
      <>
      <div className="row mt-5">
        <div className='col-lg-12'>
          <hr className='footer-line'/>
        </div>
      </div>
      <div className="row mt-4 mb-4">
        <div className='col-sm-6 contact-icon'>
          <a href="/" target="_blank" className='telegram-link'>
            <i className="bi bi-telegram"></i>
          </a>
          <a href="/" target="_blank" className='twitter-link'>
            <i className="bi bi-twitter-x"></i>
          </a>
        </div>
        <div className='col-sm-6 statur-bar'>
        {props.loggedIn ? (
        <h1 className="poppins light footer-status">Status <span className="poppins light footer-status-value">Online <i className="bi bi-circle-fill online"></i></span></h1>) 
        : (
        <h1 className="poppins light footer-status">Status <span className="poppins light footer-status-value">Offline <i className="bi bi-circle-fill offline"></i></span></h1>)}
        
        </div>
        <small className='monserrat regular footer-end'>© Copyright Rentify.</small>
      </div>
      </>
    );
}

export default FooterBar;