import Modal from 'react-bootstrap/Modal';
import MetaMaskLogo from '../img/logo-metamask.png';
import PhantomLogo from '../img/logo-phantom.png';
import CoinbaseLogo from '../img/logo-coinbase.png';
import WalletLogo from '../img/logo-wallet.png';
import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import GetApi from "../api/GetApi";
import {getAddress, getBalance } from "../api/AuthToken";
import PostLoginApi from "../api/PostLoginApi";
import CopyButton from './CopyButton';

const ConnectModal = (props) => {
    // auth
    const handleLogout = () => {
        props.onLogout(); 
    };

    const address = props.loggedIn ? getAddress() : null;
    const balance = props.loggedIn ? getBalance() : null;

    // metamask data
    const [data, setdata] = useState({
        address: "",
        Balance: null,
    });

    const [authData, setAuthData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataAuthData = await GetApi({ method: "api/auth/nonce" });
                setAuthData(dataAuthData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
 
    const btnhandler = () => {
        try {
            if (window.ethereum) {
                window.ethereum
                    .request({ method: "eth_requestAccounts" })
                    .then(async (res) => {
                        const currentAccount = await ethers.utils.getAddress(res[0])
                        await handleSignMessage(currentAccount);
                    })
                    .catch((error) => {
                        console.error('An error occurred while connecting MetaMask:', error);
                        alert('An error occurred while connecting MetaMask. Make sure you provide the required access.');
                    });
            } else {
                alert("Install MetaMask extension!!");
            }
        } catch (error) {
            console.error('An error occurred while initiating the request:', error);
            alert('An error occurred while initiating the request. Please try again.');
        }
    };

    const handleSignMessage = async (publicAddress) => {
        try {
            const signature = await window.ethereum.request({
                method: "personal_sign",
                params: [`I am signing my one-time nonce: ${authData.nonce_id}`, publicAddress],
            });
            const message = `I am signing my one-time nonce: ${authData.nonce_id}`;
            // console.log("Signature:", signature); 

            await PostLoginApi(publicAddress, message, signature);
            await accountChangeHandler(publicAddress);
        } catch (error) {
            console.error('An error occurred while signing the message:', error);
            alert('An error occurred while signing the message. Please try again.');
        }
    };
 
    const accountChangeHandler = async (address) => {
        try {
            setdata({
                address: address,
            });
            const balance = await window.ethereum.request({
                method: "eth_getBalance",
                params: [address, "latest"],
            });
            setdata((prevData) => ({
                ...prevData,
                Balance: ethers.utils.formatEther(balance),
            }));
            localStorage.setItem('address', address);
            localStorage.setItem('balance', balance);
            props.setLoggedIn(true);
        } catch (error) {
            console.error('An error occurred while fetching balance:', error);
            alert('An error occurred while fetching balance. Please try again.');
        }
    };
    
    return (
        <>
        {props.loggedIn ? (
            <Modal className='connect-modal' show={props.show} onHide={props.handleClose} centered>
            <i className="bi bi-x-circle close-btn" onClick={props.handleClose}></i>
            <div className='row connect-modal-header'>
                <h1 className='connect-modal-title text-center mt-3 rubik regular'>Connected</h1>   
            </div>
            <Modal.Body className='connect-modal-body'>
                <div className="gradient-circle mb-5"></div>
                <div className='row ps-3 pe-3'>
                    <p className='address-account rubik medium'>{address}</p>
                    <p className='address-balance rubik medium'>{balance}</p>
                    <button className='disconnect-modal-button rubik regular ps-4 mb-2' onClick={handleLogout}>
                    <i className="bi bi-box-arrow-right"></i> Disconnect
                    </button>
                </div>
            </Modal.Body>
        </Modal>
        ) : (
            <Modal className='connect-modal' show={props.show} onHide={props.handleClose} centered>
                <i className="bi bi-x-circle close-btn" onClick={props.handleClose}></i>
                <div className='row connect-modal-header'>
                    <h1 className='connect-modal-title text-center mt-3 rubik regular'>Connect Wallet</h1>   
                </div>
                <Modal.Body>
                    <div className='row connect-modal-body ps-3 pe-3'>
                        <button className='connect-modal-button rubik regular ps-4 mb-2' onClick={btnhandler}>
                            MetaMask <img className='connect-modal-button-logo' src={MetaMaskLogo}></img>
                        </button>
                        <button className='connect-modal-button rubik regular ps-4 mb-2'>
                            Phantom <img className='connect-modal-button-logo' src={PhantomLogo}></img>
                        </button>
                        <button className='connect-modal-button rubik regular ps-4 mb-2'>
                            Coinbase <img className='connect-modal-button-logo' src={CoinbaseLogo}></img>
                        </button>
                        <button className='connect-modal-button rubik regular ps-4 mb-2'>
                            Other Wallet <img className='connect-modal-button-logo' src={WalletLogo}></img>
                        </button>
                    </div>
                    
                </Modal.Body>
                <small className='connect-modal-footer rubik regular text-center mb-4'><i className="bi bi-wallet2"></i> I don’t have a wallet</small>
            </Modal>
        )}
        
        </>
    )
}

export default ConnectModal;
