import React, { useState } from 'react';

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const textarea = document.createElement('textarea');
    textarea.value = text;

    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');

    document.body.removeChild(textarea);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
        {copied ? (
            <i onClick={handleCopy} class="bi bi-clipboard-check-fill" style={{ fontSize: "1rem", color: "var(--tertiary)", cursor: "pointer", marginLeft: "10px" }}></i>
        ) : (
            <i onClick={handleCopy} class="bi bi-clipboard" style={{ fontSize: "1rem", color: "var(--tertiary)", cursor: "pointer", marginLeft: "10px" }}></i>
        )}
    </>
  );
};

export default CopyButton;
