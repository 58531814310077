import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

const ChatBubble = (props) => {
    const changeDateFormat = (dateStr) => {
        const dateObj = new Date(dateStr);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
    
        if (isSameDate(dateObj, today)) return "Today " + formatTime(dateObj);
        else if (isSameDate(dateObj, yesterday)) return "Yesterday " + formatTime(dateObj);
        else return formatDate(dateObj) + ", " + formatTime(dateObj);
    };
    const isSameDate = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    };
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const formatTime = (date) => {
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    };

    return (
        <>
        <Card id='card-chat-bubble'>
            <Card.Body className='p-0'>
                <div className='row chat-bubble mb-2 poppins medium'>
                    <div className='col-4 fit-cont' style={{ display: "grid" }}>
                        <div className="gradient-circle-chat"></div>
                    </div>
                    <div className='col-8 text-start'>
                        <div className='row pb-1'>
                            <div className='col fit-cont pt-1'>
                                <p className='chat-nickname'>
                                    {props.chat.nickname}
                                </p>
                            </div>
                            <div className='col text-start' style={{ position: "relative" }}>
                                <span className='chat-created-at'>
                                    {changeDateFormat(props.chat.created_at)}
                                </span>
                            </div>
                            </div>
                        <div className='row text-start fit-cont'>
                            <p className='chat-message'>{props.chat.message}</p>
                        </div>
                    </div>
                    <p className='chat-level mt-2 text-end'>Level 1</p> 
                </div>
            </Card.Body>
        </Card>
        <hr className='chat-bubble-line p-0 m-0 mb-3'/>
        </>
    )
}

export default ChatBubble;
