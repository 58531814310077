import FooterBar from "../components/FooterBar";
import SplashScreen from "../components/SplashScreen";
import React, { useState, useEffect } from "react";
import SideBar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import ConnectModal from "../components/ConnectModal";
import {getAddress, getNickname, getToken, getVoucher } from "../api/AuthToken";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from 'react-bootstrap/Table';
import CopyButton from "../components/CopyButton";
import GetRefferal from "../api/GetRefferal";

function Profile(props) {
    const handleLogout = () => {
        props.onLogout(); 
    };
    const [show, setShow] = useState(false);
    const [showProfileStats, setShowProfileStats] = useState(false); 
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    const handleProfileStatsClick = () => {
        setShowProfileStats(!showProfileStats);
    }
    
    const [refferalData, setRefferalData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await GetRefferal({ voucher: getVoucher(), token: getToken() });
                setRefferalData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const limitedData = refferalData.slice(0, 3);
 
    let dataCount = refferalData.length;
    let totalPoints = refferalData.reduce((total, item) => total + item.point, 0);

    const changeDateFormat = (dateStr) => {
        const dateObj = new Date(dateStr);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
    
        if (isSameDate(dateObj, today)) return "Today " + formatTime(dateObj);
        else if (isSameDate(dateObj, yesterday)) return "Yesterday " + formatTime(dateObj);
        else return formatDate(dateObj) + ", " + formatTime(dateObj);
    };
    const isSameDate = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    };
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const formatTime = (date) => {
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    };

    return (
            <>
            <SideBar loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn}/>
            <div className="container">
                <TopBar/>
                <div className="profile-main-card p-3">
                    <div className='row pt-5'>
                        <h1 className='profile-main-card-title poppins medium text-center'>PROFILE</h1>
                        <div id="profile-gradient-circle" className="mt-5 mb-5">
                          <i className="bi bi-person"></i>
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center text-center'>
                        {props.loggedIn ? (
                            <>
                            <small className="rubik medium profile-address">{getAddress()}<CopyButton text={getAddress()}/></small>
                            <h1 className='profile-nickname poppins regular mt-3'>{getNickname()}</h1>
                            <button id="profile-disconnect-button" className="mt-3 poppins medium" onClick={handleLogout}>Disconnect</button>
                            </>
                        ) : (
                            <button id="profile-connect-button" className="poppins medium" onClick={handleShow}>Connect</button>
                        )}
                    </div>
                    <div className="row text-center mt-3 p-2">
                        {props.loggedIn ? (
                            <>
                            <div id="profile-progress-note" className='row poppins semi-bold'>
                                <div className='col-6 text-start'>
                                    <h5 className='title poppins semi-bold mb-1'>Level 1</h5>
                                </div>
                                <div className='col-6 text-end'>
                                    <h5 className='title poppins semi-bold mb-1 pe-4'>Level 2</h5>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <ProgressBar id='profile-progress-bar-section' variant="PRIMARY" now={74} animated/>  
                            </div>
                            {!showProfileStats ? (
                                <>
                                <div id="profile-refferal" className="row">
                                <div className="col-md-6 mt-5 mb-3">
                                    <h5 id="profile-refferal-title" className="poppins regular">Refferal Code</h5>
                                    <div id="profile-refferal-code" className="rubik medium" style={{display: "inline-block", margin: "0 auto"}}>{getVoucher()}</div>
                                    <CopyButton text={getVoucher()}/>
                                </div>

                                    <div className="col-md-6">
                                        <div id="profile-refferal-card" className="pb-3">
                                            <div className="row mt-3">
                                                <div className="col-md-6 mb-2">
                                                    <div id="profile-refferal-code" className="rubik medium">
                                                        Total Refferal : <span style={{ color: "var(--primary)" }}>{dataCount}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <div id="profile-refferal-code" className="rubik medium">
                                                        Total Point : <span style={{ color: "var(--primary)" }}>{totalPoints}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="profile-refferal-history-card mt-3 mb-3 poppins regular pt-2 pb-2">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Address</th>
                                                            <th>Date</th>
                                                            <th>Refferal Code</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {limitedData.map((refferal, index) => (
                                                            <tr>
                                                                <td>{(refferal.user_id).substring(0, 8)}...</td>
                                                                <td>{changeDateFormat(refferal.time)}</td>
                                                                <td>{getVoucher()}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <a href="#" className="profile-refferal-check poppins medium" onClick={handleProfileStatsClick}>Check Profile stats</a>
                                        </div>
                                    </div>
                                </div>
                                </>
                            ) : (
                                <>
                                <div className="profile-refferal-history-card mt-3 mb-3 poppins regular pt-2 pb-2">
                                    <Table responsive scroll>
                                        <thead>
                                            <tr>
                                                <th>Address</th>
                                                <th>Date</th>
                                                <th>Refferal Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {refferalData.map((refferal, index) => (
                                                <tr>
                                                    <td>{(refferal.user_id).substring(0, 8)}...</td>
                                                    <td>{changeDateFormat(refferal.time)}</td>
                                                    <td>{getVoucher()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>                                
                                <a href="#" className="profile-refferal-check poppins medium" onClick={handleProfileStatsClick}>Close Profile stats</a>
                                </>
                            )}
                            </>
                        ) : (
                            <>
                            <h1 id="profile-hello-title" className="poppins medium">Hello there, and welcome aboard!</h1>
                            <p id="profile-hello-desc" className="poppins medium">Unlock more rewards and level up faster by simply referring friends. Each referral earns you points towards exclusive perks. Start sharing and start earning today!</p>
                            </>
                        )}
                        
                    </div>
                </div>
                <FooterBar loggedIn={props.loggedIn}/>
            </div>
            <ConnectModal show={show} handleClose={handleClose} loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} onLogout={props.onLogout}/>
            </>
    );
}
export default SplashScreen(Profile);