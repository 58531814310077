import WorldMap from '../img/world-map.png';
import ConnectModal from './ConnectModal';
import { useState } from 'react';
import {getAddress } from "../api/AuthToken";

const DashboardMainCard = (props) => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <div className="row mt-1 dashboard-main-card pt-5 ps-4 pb-5">
            <div className="row">
                <div className="col-sm-12 dashboard-main-card-title mt-4">
                    <h1 className="poppins semi-bold">Live <span className="poppins semi-bold">Server</span></h1>
                </div>
                {props.loggedIn ? (
                <>
                <h1 className="rubik medium dashboard-main-card-connect-card-1">1 Level</h1><br/>
                <h1 className="rubik medium dashboard-main-card-connect-card-2">7 Points</h1><br/>
                <h1 className="rubik medium dashboard-main-card-connect-card-3" onClick={handleShow}>
                    {getAddress().substring(0, 8)}...
                </h1>
                </>
                ) : (
                <h1 className="rubik medium dashboard-main-card-connect" onClick={handleShow}>Connect Wallet</h1>
                )}
                    
            </div>
            <div className="row">
                <div className="col-lg-12 text-center pt-3">
                    <img src={WorldMap} className="dashboard-world-img"></img>
                </div>
            </div>
            <div className='row ps-4 pe-4 mt-5'>
                <div className='col-6'>
                    <h5 className='dashboard-bar-title poppins semi-bold mb-1'>World Server</h5>
                </div>
                <div className='col-6 text-end'>
                <h5 className='dashboard-bar-title poppins semi-bold mb-1'>Status : Online <i className="bi bi-circle-fill"></i></h5>
                </div>
                <hr className='dashboard-bar'/>
            </div>
            <div className='row mt-3 d-flex justify-content-center text-center'>
                <div className='col-2'>
                    <h5 className='dashboard-description-title title-1 poppins semi-bold mb-1'><i className="bi bi-circle-fill"></i> 0-512 User</h5>
                </div>
                <div className='col-2'>
                    <h5 className='dashboard-description-title title-2 poppins semi-bold mb-1'><i className="bi bi-circle-fill"></i> 512-1024 User</h5>
                </div>
                <div className='col-2'>
                    <h5 className='dashboard-description-title title-3 poppins semi-bold mb-1'><i className="bi bi-circle-fill"></i> 1024-2048 User</h5>
                </div>
                <div className='col-2'>
                    <h5 className='dashboard-description-title title-4 poppins semi-bold mb-1'><i className="bi bi-circle-fill"></i> 2048-4096 User</h5>
                </div>
            </div>
        </div>

        <ConnectModal show={show} handleClose={handleClose} loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} onLogout={props.onLogout}/>
        </>
    )
}

export default DashboardMainCard;