import { useState, useEffect } from 'react';

const CryptoCompareApi = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://resplendent-laughter-production.up.railway.app/api/rate/get?gpu-price=${props.price}&currency=ETH`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${props.token}`
          },
        });
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 180000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.amount) {
      localStorage.setItem('amount', JSON.stringify(data.data.amount));
    }
  }, [data]);

  if (!data || !data.data || !data.data.amount) {
    return "Loading.."; 
  }

  return data.data.amount + " ETH";
};

export default CryptoCompareApi;
