const GetChatApi = () => {
    return new Promise((resolve, reject) => {
        const socket = new WebSocket("wss://resplendent-laughter-production.up.railway.app/api/chat/websocket");
        
        socket.onopen = () => {
        };
        
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            resolve(data);
            socket.close();
        };
        
        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject([]);
        };
    });
};

export default GetChatApi;
