import FooterBar from "../components/FooterBar";
import SideBar from "../components/Sidebar";
import ListCardItem from "../components/ListCardItem";
import SplashScreen from "../components/SplashScreen";
import GetApi from "../api/GetApi";
import React, { useState, useEffect } from "react";
import TopBar from "../components/TopBar";

const List = (props) => {
    const [gpuData, setGpuData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await GetApi({ method: "api/gpu/list" });
                setGpuData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
    <>
    <SideBar loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn}/>
    <div className="container">
        <TopBar/>
        <div className="list-main-card p-3">
            <div className='row ps-3 pt-4'>
                <h1 className='list-main-card-title poppins medium'>Select GPU</h1>
            </div>
            <div className="row justify-content-center text-center mt-5">
                {gpuData.map((gpu, index) => (
                    <ListCardItem key={index} gpu={gpu}/>
                ))}
            </div>
        </div>
        
        <FooterBar loggedIn={props.loggedIn}/>
    </div>
    
    </>
    );
}

export default SplashScreen(List);