import { jwtDecode } from "jwt-decode";

const PostLoginApi = async (public_address, message, signed_message) => {
    try {
        const url = 'https://resplendent-laughter-production.up.railway.app/api/auth/login';

        const requestBody = JSON.stringify({
            public_address,
            message,
            signed_message
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        let token = data.data.token;

        const nickname = jwtDecode(token).nickname;
        const voucher = jwtDecode(token).voucher_id;

        localStorage.setItem('token', token);
        localStorage.setItem('nickname', nickname);
        localStorage.setItem('voucher', voucher);
        
        return token;
    } catch (error) {
        console.error('There was an error!', error);
        alert('Internal Server Error');
    }
};

export default PostLoginApi;
