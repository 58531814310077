const TopBar = () => {
    return (
        <>
        <div className="row mt-5 mb-4">
            <div className="dashboard-top-1 col-lg-4 d-flex">
                <h1 className="poppins medium dashboard-top-title">$RENT Price <span className="poppins medium dashboard-top-value">$0.23</span></h1>
            </div>
            <div className="dashboard-top-2 col-lg-4 d-flex">
                <h1 className="poppins medium dashboard-top-title">FDV <span className="poppins medium dashboard-top-value">$23,251,522</span></h1>
            </div>
            <div className="dashboard-top-3 col-lg-4 d-flex">
                <h1 className="poppins medium dashboard-top-title">ETH Price <span className="poppins medium dashboard-top-value">$3,102.72</span></h1>
            </div>
        </div>
        </>
    )
}

export default TopBar;