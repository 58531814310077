import Card from 'react-bootstrap/Card';

const CardDetail = (props) => {

    return (
        <>
        <div className="col-lg-4 mb-3 card-item">
            <Card style={{ width: '18rem', paddingTop: '2rem' }}>
                <Card.Body>
                    <i className={`bi bi-${props.icon} icon-card-detail`}></i>
                    <Card.Subtitle className="mb-2 text-muted">{props.type}</Card.Subtitle>
                    <Card.Title>{props.name}</Card.Title>
                </Card.Body>
            </Card>
        </div>
        </>
    );
}

export default CardDetail;