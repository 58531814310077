import React, { Component } from 'react';
import logo from '../img/logo.svg';


function SplashMessage({ loading }) {
    return (
        <div className={`splash-message ${loading ? 'fade-in' : 'fade-out'}`} style={{ backgroundColor: "var(--secondary)" }}>
            <img src={logo} className="splash-logo" alt="logo" />
        </div>
    );
}

export default function SplashScreen(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 1000)
            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            return (
                <div className="splash-container">
                    <SplashMessage loading={this.state.loading} />
                    {this.state.loading ? null : <WrappedComponent {...this.props} />}
                </div>
            );
        }
    };
}