import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { getToken, getAddress } from "../api/AuthToken";
import GetOrderApi from '../api/GetOrderApi';
import React, { useEffect, useState } from 'react';

const OrderTable = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
        try {
            const token = getToken();
            const address = getAddress();
            const data = await GetOrderApi(address, token);
            setOrders(data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };
    fetchOrders();
    console.log(orders);
}, []);
  
  return (
    <Table className='profile-refferal-history-card' responsive="lg">
    <thead>
      <tr>
        <th>#</th>
        <th>Order ID</th>
        <th>GPU</th>
        <th>Value</th>
        <th>Voucher</th>
        <th>Payment Address</th>
        <th>Payment Transaction</th>
        <th>Price</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {orders.map((order, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{order.order_id}</td>
          <td>{order.gpu_name}</td>
          <td>{order.value}</td>
          <td>{order.voucher}</td>
          <td>{order.payment_address}</td>
          <td>{order.payment_transaction}</td>
          <td>{order.total}</td>
          <td>
            <Badge bg={order.status === "Checking Payment" ? "warning" : "success"} text="dark">
              {order.status}
            </Badge>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
  );
}
export default OrderTable;