import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import OrderModal from './OrderModal';

const ListCardItem = (props) => {
    const { gpu } = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div id='card-item' className="col-lg-4 col-md-6 col-sm-12 mb-3">
            <Card id='card-card-item'>
                <Card.Body>
                    <p className='card-item-processor'>Intel Gen Intel® Core™ i5-13500</p>
                    {gpu.available ? (
                        <p className='card-item-available-status poppins-medium'>Available</p>
                    ) : (
                        <p className='card-item-available-status poppins-medium unavailable'>Unavailable</p>
                    )}
                    <h1 className='card-item-title poppins medium'>{gpu.gpu_name}</h1>
                    <div className='row poppins medium'>
                        <div className='col-5 cores pt-1'>{gpu.cpu_core}</div>
                        <div className='col-3 gpu-cpu text-start'>CPU</div>
                        <div className='col-3 gpu-cpu text-start'>GPU</div>
                    </div>
                    <div className='row poppins medium'>
                        <div className='col-5 cores pt-1'>{gpu.GpuCores}</div>
                        <div className='col-6 cores pt-1 ps-4 text-start'>{gpu.network}</div>
                    </div>
                    <div className='row card-item-price poppins medium mt-3'>
                        <div className='col-6 text-start'>
                            <div id='card-item-price-title' className='row'>
                                <p>Price per month</p>
                            </div>
                            <div id='card-item-price-value' className='row'>
                                <p>${gpu.price}</p>
                            </div>
                        </div>
                        <div className='col-6 justify-content-start d-flex'>
                            <button onClick={handleShow} disabled={!gpu.available} id={!gpu.available ? 'disabled-button' : 'card-item-price-button'}>Buy</button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <OrderModal show={show} handleClose={handleClose} gpu={gpu}/>
        </div>
    );
}

export default ListCardItem;