import FooterBar from "../components/FooterBar";
import SideBar from "../components/Sidebar";
import SplashScreen from "../components/SplashScreen";
import GetApi from "../api/GetApi";
import React, { useState, useEffect } from "react";
import DashboardMainCard from "../components/DashboardMainCard";
import DashboardDetailCard from "../components/DashboardDetailCard";
import TopBar from "../components/TopBar";

const Dashboard = (props) => {
    const [gpuData, setGpuData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await GetApi({ method: "api/gpu/list" });
                setGpuData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
    <>
        <SideBar loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn}/>
        <div className="container">
            <TopBar/>
            <DashboardMainCard loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn} onLogout={props.handleLogout}/>
            <div className="row mt-4">
                <DashboardDetailCard title="Total Nodes" desc="32"/>
                <DashboardDetailCard title="Total GPUs" desc="100"/>
                <DashboardDetailCard title="Total Rent" desc="43"/>
                <DashboardDetailCard title="Average Rent Duration" desc="52.201 hrs"/>
                <DashboardDetailCard title="Total Rent Duration" desc="8,398 hrs"/>
                <DashboardDetailCard title="Average Rent Cost" desc="$42,4"/>
            </div>
            <FooterBar loggedIn={props.loggedIn}/>
        </div>
    </>
    );
}

export default SplashScreen(Dashboard);