import CardDetail from "../components/CardDetail";
import FooterBar from "../components/FooterBar";
import GetApi from "../api/GetApi";
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'; 
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import OrderModal from '../components/OrderModal';
import SplashScreen from "../components/SplashScreen";
import Card from 'react-bootstrap/Card';
import SideBar from "../components/Sidebar";
import TopBar from "../components/TopBar";

const DetailItem  = (props) => {
    const { id } = useParams();
    // API
    const [gpuData, setGpuData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await GetApi({ method: `api/gpu/list/${id}` });
                setGpuData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (!gpuData) return <div>Data not found</div>;

    return (
        <>
        <SideBar loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn}/>
        <div className="container">
                <TopBar/>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-4 text-center justify-content-center">
                    <h1>{gpuData.gpu_name}</h1>
                    <hr style={{ width: "15rem", margin: "1rem auto 0.5rem auto" }}/>
                    {gpuData.available ? (
                        <Badge bg="success">Available</Badge>
                    ) : (
                        <Badge bg="danger">Not Available</Badge>
                    )}
                </div>
            </div>
            <div className="row justify-content-center text-center mt-5">
                <div className="col-lg-12 mb-3 card-item">
                    <Card style={{ width: '18rem', paddingTop: '2rem' }}>
                        <Card.Body>
                            <Card.Subtitle className="mb-2 text-muted">Description</Card.Subtitle>
                            <Card.Title>{gpuData.description}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Price</Card.Subtitle>
                            <Card.Title>${gpuData.price}</Card.Title>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="row justify-content-center text-center">
                <CardDetail type="GPU" name={gpuData.name} icon="gpu-card"/>
                <CardDetail type="NETWORK" name={gpuData.network} icon="pci-card-network"/>
                <CardDetail type="MEMORY" name={gpuData.memory} icon="memory"/>
                <CardDetail type="LINK" name={gpuData.link} icon="router-fill"/>
                <CardDetail type="CPU" name={gpuData.cpu} icon="cpu-fill"/>
                <CardDetail type="STORAGE" name={gpuData.storage} icon="nvme-fill"/>
            </div>
            <div className="row justify-content-center text-center mt-2">
                <Button variant="success" onClick={handleShow} disabled={!gpuData.available} style={{ width: "100px" }}><i className="bi bi-currency-dollar"></i> Rent</Button>{' '}
            </div>
            <FooterBar loggedIn={props.loggedIn}/>
        </div>
        <OrderModal show={show} handleClose={handleClose} gpu={gpuData}/>
        </>
    )
}

export default SplashScreen(DetailItem);