import FooterBar from "../components/FooterBar";
import OrderTable from "../components/OrderTable";
import SplashScreen from "../components/SplashScreen";
import SideBar from "../components/Sidebar";
import TopBar from "../components/TopBar";

const Order = (props) => {
    return (
        <>
        <SideBar loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn}/>
        <div className="container">
                <TopBar/>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-4 text-center justify-content-center">
                    <h1 className="poppins regular" style={{ color: "white" }}>Order</h1>
                    <hr style={{ width: "15rem", margin: "1rem auto 0 auto" }}/>
                </div>
            </div>
            <div className="row justify-content-center text-center mt-5">
                <OrderTable/>
            </div>
            <FooterBar loggedIn={props.loggedIn}/>
        </div>
        </>
    );
}

export default SplashScreen(Order);