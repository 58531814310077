const SendChatApi = (message, userId, nickname) => {
    return new Promise((resolve, reject) => {
        const socket = new WebSocket("wss://resplendent-laughter-production.up.railway.app/api/chat/websocket");
        
        socket.onopen = () => {
            const payload = {
                message: message,
                user_id: userId,
                nickname: nickname
            };
            socket.send(JSON.stringify(payload));
        };
        
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            resolve(data);
            socket.close();
        };
        
        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject([]);
        };
    });
};

export default SendChatApi;
