const GetApi = async (props) => {
    try {
        const response = await fetch(`https://resplendent-laughter-production.up.railway.app/${props.method}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

export default GetApi;
