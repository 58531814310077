const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return token !== null; 
};

const getToken = () => {
    return localStorage.getItem('token');
};

const getNickname = () => {
    return localStorage.getItem('nickname');
};

const getAddress = () => {
    return localStorage.getItem('address');
};

const getBalance = () => {
    return localStorage.getItem('balance');
};

const getVoucher = () => {
    return localStorage.getItem('voucher');
};

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('nickname');
    localStorage.removeItem('address');
    localStorage.removeItem('balance');
    localStorage.removeItem('voucher');
};

export { isAuthenticated, getToken, getNickname, getAddress, getBalance, getVoucher, logout };
