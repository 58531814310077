import React, { useState, useEffect } from 'react';
import FooterBar from "../components/FooterBar";
import SplashScreen from "../components/SplashScreen";
import ChatBubble from '../components/ChatBubble';
import Form from 'react-bootstrap/Form';
import GetChatApi from '../api/GetChatApi';
import SendChatApi from '../api/SendChatApi';
import { getNickname, getAddress } from "../api/AuthToken";
import SideBar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import { isAuthenticated } from "../api/AuthToken";

function LiveChat(props) {
    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState("");

    useEffect(() => {
        props.setLoggedIn(isAuthenticated());
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const chatData = await GetChatApi();
                setChats(chatData);
            } catch (error) {
                console.error('Error fetching chat data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await SendChatApi(message, getAddress(), getNickname());
            
            const chatData = await GetChatApi();
            setChats(chatData);
            setMessage("");
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <>
            <SideBar loggedIn={props.loggedIn} setLoggedIn={props.setLoggedIn}/>
            <div className="container">
                <TopBar/>
                <div className="chat-main-card p-3">
                    <div className='row ps-3 pt-4'>
                        <h1 className='chat-main-card-title poppins medium'>Community Chat</h1>
                    </div>
                    <div className="row justify-content-center text-center mt-5">
                        <div>
                            {chats
                                .slice() 
                                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                                .map((chat, index) => (
                                    <ChatBubble key={index} chat={chat}/>
                                ))}
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <div className="row mt-5 form-chat">
                                <div className='col-1 form-chat-icon fit-cont'>
                                    <i className="bi bi-plus-lg"></i>
                                </div>
                                <div className='col-6 form-chat-input mt-3 poppins medium fit-cont'>
                                    <Form.Group className="mb-3">
                                    {props.loggedIn ? (
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter message" 
                                            value={message} 
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    ) : (
                                        <Form.Control 
                                            type="text"
                                            placeholder="You Must Connected Wallet" 
                                            value={message} 
                                            onChange={(e) => setMessage(e.target.value)}
                                            disabled
                                        />
                                    )}
                                        
                                    </Form.Group>
                                </div>
                                <div className='col-1 form-chat-button fit-cont'>
                                {props.loggedIn ? (
                                    <button type="submit">
                                        <i className="bi bi-send-fill"></i>
                                    </button>
                                ) : (
                                    <button type="submit" disabled style={{ cursor: "not-allowed" }}>
                                        <i className="bi bi-send-fill"></i>
                                    </button>
                                )}
                                    
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            <FooterBar loggedIn={props.loggedIn}/>
            </div>
        </>
    );
}

export default SplashScreen(LiveChat);
