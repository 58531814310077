const GetRefferal = async (props) => {
    try {
        const response = await fetch(`https://resplendent-laughter-production.up.railway.app/api/voucher/${props.voucher}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${props.token}`
            },
          });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

export default GetRefferal;
