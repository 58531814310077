import Card from 'react-bootstrap/Card';

const DashboardDetailCard = (props) => {
    return (
        <>
        <div className="col-md-4 mb-3">
            <Card id='dashboard-detail-card'>
                <Card.Body id="dashboard-detail-card-body" className='text-start'>
                    <h5 className='dashboard-detail-card-title poppins medium'>{props.title}</h5>
                    <h5 className='dashboard-detail-card-desc poppins medium'>{props.desc}</h5>
                </Card.Body>
            </Card>
        </div>
        </>
    )
}

export default DashboardDetailCard;