const GetOrderApi = async (userId, token) => {
    try {
        const response = await fetch(`https://resplendent-laughter-production.up.railway.app/api/order/get/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

export default GetOrderApi;
