import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import * as Yup from "yup";
import PostOrderApi from "../api/PostOrderApi";
import { getToken, getAddress } from "../api/AuthToken";
import { useNavigate } from 'react-router-dom';
import CryptoCompareApi from '../api/CryptoCompareApi';

const OrderModal = (props) => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            telegram: '',
            email: '',
            sshkey: '',
            value: '',
            voucher_id: '',
            wallet: '',
            hash: ''
        },
        validationSchema: Yup.object({
            telegram: Yup.string()
              .matches(/^@/, "Telegram must start with '@'")
              .required("Required!"),
            email: Yup.string()
              .email("Invalid email format")
              .required("Required!"),
            sshkey: Yup.string()
              .matches(/^(ssh-rsa|ssh-ed25519|ecdsa-sha2-nistp256|ecdsa-sha2-nistp384|ecdsa-sha2-nistp521)\s/, "SSH key must start with 'ssh-rsa', 'ssh-ed25519', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384', or 'ecdsa-sha2-nistp521'")
              .required("Required!"),
            value: Yup.string().required("Required!"),
            hash: Yup.string()
              .required("Required!"),
          }),
        onSubmit: async values => {
            try {
                const response = await PostOrderApi(
                    values.email,
                    values.telegram,
                    values.hash,
                    values.value,
                    values.sshkey,
                    values.voucher_id,
                    props.gpu.id, 
                    getAddress(),
                    getToken(),
                    navigate
                );
            } catch (error) {
                console.error(error);
            }
        }
    });

    return (
        <Modal className='connect-modal rubik regular modal-order' show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title className='connect-modal-header'>Ordering Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
                {/* Telegram */}
                <div className="mb-3">
                    <label className='label-form'>Telegram @<span style={{ color: "red" }}>*</span></label>
                    <small style={{ color: "var(--tertiary)", fontSize: "10px" }}>
                        {' '} Telegram must start with "@"
                    </small>
                    <input type="text" name="telegram" className="form-control" value={formik.values.telegram} onChange={formik.handleChange}/>
                    {formik.errors.telegram && formik.touched.telegram && (
                        <p className='error-msg'>{formik.errors.telegram}</p>
                    )}
                </div>

                {/* Email */}
                <div className="mb-3">
                    <label className='label-form'>Email address<span style={{ color: "red" }}>*</span></label>
                    <input type="email" name="email" className="form-control" value={formik.values.email} onChange={formik.handleChange}/>
                    {formik.errors.email && formik.touched.email && (
                        <p className='error-msg'>{formik.errors.email}</p>
                    )}
                </div>
                
                {/* SSH Key */}
                <div className="mb-3">
                    <label className='label-form'>SSH Key<span style={{ color: "red" }}>*</span></label>
                    <input type="text" name="sshkey" className="form-control" value={formik.values.sshkey} onChange={formik.handleChange}/>
                    {formik.errors.sshkey && formik.touched.sshkey && (
                        <p className='error-msg'>{formik.errors.sshkey}</p>
                    )}
                </div>

                {/* Payment Method */}
                <div className="mb-3">
                    <label className='label-form'>Payment Method<span style={{ color: "red" }}>*</span></label>
                    <div key="default-radio form-check" className="mb-3">
                        <input className="form-check-input" label="ETH" name="value" type="radio" id="inline-radio-1" value="ETH" onChange={formik.handleChange} />
                        <label className="text-payment-method">
                            <CryptoCompareApi price={props.gpu.price} token={getToken()}/>
                        </label>
                        <input className="form-check-input" label="$USDT" name="value" type="radio" id="inline-radio-2" value="$USDT" onChange={formik.handleChange} />
                        <label className="text-payment-method">
                            ${props.gpu.price}
                        </label>
                    </div>
                    {formik.errors.value && formik.touched.value && (
                        <p className='error-msg'>{formik.errors.value}</p>
                    )}
                </div>

                {/* Voucher */}
                <div className="mb-3">
                    <label className='label-form'>Voucher</label>
                    <input type="text" name="voucher_id" className="form-control" value={formik.values.voucher_id} onChange={formik.handleChange}/>
                    {formik.errors.voucher_id && formik.touched.voucher_id && (
                        <p className='error-msg'>{formik.errors.voucher_id}</p>
                    )}
                </div>

                {/* ETH Wallet Address */}
                <div className="mb-3">
                    <label className='label-form'>ETH Wallet Address<span style={{ color: "red" }}>*</span></label>
                    <input type="text" name="wallet" className="form-control" value={formik.values.wallet} onChange={formik.handleChange} disabled/>
                    <small style={{ color: "var(--tertiary)", fontSize: "10px" }}>
                        Note: Minimum purchase time is 1 week.
                    </small>
                    {formik.errors.wallet && formik.touched.wallet && (
                        <p className='error-msg'>{formik.errors.wallet}</p>
                    )}
                </div>

                {/* Transaction Hash */}
                <div className="mb-3">
                    <label className='label-form'>Transaction Hash<span style={{ color: "red" }}>*</span></label>
                    <input type="text" name="hash" className="form-control" value={formik.values.hash} onChange={formik.handleChange}/>
                    {formik.errors.hash && formik.touched.hash && (
                        <p className='error-msg'>{formik.errors.hash}</p>
                    )}
                </div>

                {/* Submit Button */}
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant="success" type="submit" className='ms-2'>
                    <i className="bi bi-currency-dollar"></i> Rent
                </Button>
            </form>             
            </Modal.Body>
        </Modal>
    );
}

export default OrderModal;