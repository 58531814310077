import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import List from './pages/List';
import Profile from './pages/Profile';
import Order from './pages/Order';
import DetailItem from './pages/DetailItem';
import LiveChat from './pages/LiveChat';
import React, { useState } from "react";
import { isAuthenticated, logout } from "./api/AuthToken";


function App() {
  // auth
  const [loggedIn, setLoggedIn] = useState(isAuthenticated());

  const handleLogout = () => {
      logout();
      setLoggedIn(false);
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Dashboard loggedIn={loggedIn} setLoggedIn={setLoggedIn} handleLogout={handleLogout}/>} />
            <Route path='/list' element={<List loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
            <Route path='/profile' element={<Profile loggedIn={loggedIn} setLoggedIn={setLoggedIn} onLogout={handleLogout}/>} />
            <Route path='/order' element={loggedIn ? <Order loggedIn={loggedIn} setLoggedIn={setLoggedIn} /> : <Navigate to="/profile" />}/>
            <Route path='/chat' element={<LiveChat loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}/>
            <Route path='/detail/:id' element={<DetailItem loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
