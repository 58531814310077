const PostOrderApi = async (email, telegram, hash, value, sshkey, voucher_id, gpu_id, user_id, token, navigate) => {
    
    try {
        const url = 'https://resplendent-laughter-production.up.railway.app/api/order/create';

        const requestBody = JSON.stringify({
            email,
            telegram,
            hash, 
            value, 
            sshkey, 
            voucher_id,
            gpu_id, 
            user_id
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            body: requestBody
        });

        if (!response.ok) {
            const responseData = await response.json();
            throw new Error(`HTTP error! ${responseData.data}`);
        }

        const data = await response.json();
        alert("GPU ordered successfully!");
        navigate("/order");
        
        return data;
    } catch (error) {
        console.error('There was an error!', error);
        alert(`Error: ${error.message}`);
    }
}

export default PostOrderApi;
